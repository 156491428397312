<template>
    <section class="invoice-preview-wrapper">
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="invoiceData === undefined">
            <h4 class="alert-heading">
                Error fetching invoice data
            </h4>
            <div class="alert-body">
                No invoice found with this invoice id. Check
                <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }">
                    Invoice List
                </b-link>
                for other invoices.
            </div>
        </b-alert>

        <b-row v-if="invoiceData" class="invoice-preview">
            <!-- Col: Left (Invoice Container) -->
            <b-col cols="12" xl="12" md="12">
                <b-card no-body class=" invoice-preview-card">
                    <!-- Header -->
                    <b-card-body style="background-color:#133842; color: white" class="invoice-padding pb-0">
                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                            <!-- Header: Left Content -->
                            <div>
                                <div class="logo-wrapper">
                                    <quote-logo :invoiceLogo="settingURL + 'invoice_logo.png'" />
                                </div>

                                <p class="card-text mb-25">{{ $func.getName() }}, {{ $func.getStreet() }} <br /></p>
                                <p class="card-text mb-25">{{ $func.getTown() }}, {{ $func.getPostCode() }}, {{ $func.getCountry() }}</p>
                                <p class="card-text mb-0">
                                    {{ $func.getPhone() }}
                                </p>
                            </div>

                            <!-- Header: Right Content -->
                            <div class="mt-md-0 mt-2">
                                <h4 style="background-color:#133842; color: white" class="invoice-title">
                                    Version ID
                                    <span class="invoice-number">#{{ invoiceData.id }}</span>
                                </h4>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">
                                        Date Issued:
                                    </p>
                                    <p class="invoice-date">
                                        {{ invoiceData.date }}
                                    </p>
                                </div>
                                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">
                                        Due Date:
                                    </p>
                                    <p class="invoice-date">
                                        {{ invoiceData.dueDate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </b-card-body>

                    <!-- Spacer -->
                    <hr class="invoice-spacing" />

                    <!-- Invoice Client & Payment Details -->
                    <b-card-body v-if="invoiceData.customerId" class="invoice-padding pt-0">
                        <b-row class="invoice-spacing">
                            <!-- Col: Invoice To -->
                            <b-col cols="12" xl="6" class="p-0">
                                <h5 class="mb-2">
                                    <strong> Invoice To:</strong>
                                </h5>
                                <h6 class="mb-25">
                                    {{ invoiceData.get_customer.name }}
                                </h6>

                                <p class="card-text mb-25">
                                    {{ invoiceData.get_customer.address }}
                                </p>
                                <p class="card-text mb-25">
                                    {{ invoiceData.get_customer.email }}
                                </p>
                            </b-col>

                            <!-- Col: Payment Details -->
                            <b-col xl="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end">
                                <!-- <div>
                  <h6 class="mb-2">
                    Payment Details:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total Due:
                        </td>
                        <td><span class="font-weight-bold"> $ {{ $func.formatPrice(invoiceData.total) }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Bank name:
                        </td>
                        <td>{{ invoiceData.bankName }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Country:
                        </td>
                        <td>{{ invoiceData.country }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          IBAN:
                        </td>
                        <td>{{ invoiceData.iban }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          SWIFT code:
                        </td>
                        <td>{{ invoiceData.swiftCode }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div> -->
                            </b-col>
                        </b-row>
                    </b-card-body>

                    <!-- Invoice Description: Table -->
                    <b-table
                        responsive
                        :items="invoiceData.items"
                        :fields="[
                            { key: 'description', thStyle: 'background-color: #133842;color:white;', thClass: 'text-center' },
                            { key: 'po_number', thStyle: 'background-color: #133842;color:white', class: 'text-center' },
                            { key: 'quantity', thStyle: 'background-color: #133842;color:white', class: 'text-center' },
                            { key: 'cost', thStyle: 'background-color: #133842;color:white', class: 'text-center' },
                            { key: 'subtotal', thStyle: 'background-color: #133842;color:white', class: 'text-center' },

                            { key: 'vat', thStyle: 'background-color: #133842;color:white', class: 'text-center' },
                            { key: 'total', thStyle: 'background-color: #133842;color:white', class: 'text-center' },
                        ]"
                    >
                        <template #cell(po_number)="data">
                            <b-card-text class="font-weight-bold mb-25">
                                {{ data.item.poNumber }}
                            </b-card-text>
                        </template>
                        <template #cell(description)="data">
                            <b-card-text class="ml-4 font-weight-bold mb-25">
                                {{ data.item.itemTitle }}
                            </b-card-text>
                            <b-card-text class=" ml-4 text-nowrap">
                                <small> {{ data.item.description }}</small>
                            </b-card-text>
                        </template>

                        <template #cell(quantity)="data">
                            <b-card-text class="font-weight-bold mb-25">
                                {{ data.item.qty }}
                            </b-card-text>
                        </template>

                        <template #cell(vat)="data">
                            <b-card-text class="font-weight-bold mb-25 text-center"> {{ data.item.vat }}% </b-card-text>
                        </template>

                        <template #cell(cost)="data">
                            <b-card-text class="font-weight-bold mb-25">{{ $func.formatPrice(data.item.cost, invoiceData.currency) }} </b-card-text>
                        </template>

                        <template #cell(subtotal)="data">
                            <b-card-text class="font-weight-bold mb-25">{{ $func.formatPrice(data.item.subtotal, invoiceData.currency) }} </b-card-text>
                        </template>

                        <template #cell(total)="data">
                            <b-card-text class="font-weight-bold mb-25">{{ $func.formatPrice(data.item.total, invoiceData.currency) }} </b-card-text>
                        </template>
                    </b-table>

                    <!-- Invoice Description: Total -->
                    <b-card-body class="invoice-padding pb-0">
                        <b-row>
                            <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2"> </b-col>
                            <!-- Col: Total -->
                            <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                                <div class="invoice-total-wrapper">
                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">
                                            Subtotal:
                                        </p>
                                        <p class="invoice-total-amount">{{ $func.formatPrice(invoiceData.subtotal, invoiceData.currency) }}</p>
                                    </div>

                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">
                                            VAT:
                                        </p>
                                        <p class="invoice-total-amount">{{ $func.formatPrice(invoiceData.tax, invoiceData.currency) }}</p>
                                    </div>
                                    <hr class="my-50" />
                                    <div class="invoice-total-item">
                                        <p class="invoice-total-title">
                                            Total:
                                        </p>
                                        <p class="invoice-total-amount">{{ $func.formatPrice(invoiceData.total, invoiceData.currency) }}</p>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>

                    <!-- Spacer -->
                    <hr class="invoice-spacing" />

                    <!-- Note -->
                    <b-card-body v-if="invoiceData.note" class="invoice-padding pt-0">
                        <span class="font-weight-bold">Note: </span>
                        <span>{{ invoiceData.note }}</span>
                    </b-card-body>
                </b-card>
            </b-col>

          
        </b-row>
      


    </section>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import { BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,BCollapse } from "bootstrap-vue";
import QuoteLogo from "@core/layouts/components/QuoteLogo.vue";
import Ripple from "vue-ripple-directive";
import invoiceStoreModule from "../invoiceStoreModule";
import InvoiceSidebarSendInvoice from "../InvoiceSidebarSendInvoice.vue";
import InvoiceSidebarAddPayment from "../InvoiceSidebarAddPayment.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
export default {
    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },

    props: {
    invoiceData: {
      type: Object,
      required: true,
    },
  },

    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BTableLite,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BCollapse,

        QuoteLogo,
        InvoiceSidebarAddPayment,
        InvoiceSidebarSendInvoice,

        AppTimeline,
        AppTimelineItem,
    },
    setup() {

        const INVOICE_STORE_MODULE_NAME = "invoice";

        // Register module
        if (!store.hasModule(INVOICE_STORE_MODULE_NAME)) store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(INVOICE_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_STORE_MODULE_NAME);
        });



        return {
            settingURL: store.state.app.settingURL,
            tableColumns: [
             { key: "date", sortable: true, class: "text-center" },
             { key: "status", sortable: true, class: "text-center" },
             { key: "actions", sortable: true, class: "text-center" },

         ],
         historyData:[],
        };
    },

    

};
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
    nav {
        display: none;
    }

    // Global Styles
    body {
        background-color: transparent !important;
    }

    nav.header-navbar {
        display: none;
    }

    .main-menu {
        display: none;
    }

    .header-navbar-shadow {
        display: none !important;
    }

    .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
    }

    footer.footer {
        display: none;
    }

    .card {
        background-color: transparent;
        box-shadow: none;
    }

    .customizer-toggle {
        display: none !important;
    }

    .theadClass {
        background-color: #133842 !important;
        color: white !important;
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {
        .row.invoice-preview {
            .col-md-8 {
                max-width: 100%;
                flex-grow: 1;
            }

            .invoice-preview-card {
                .card-body:nth-of-type(2) {
                    .row {
                        > .col-12 {
                            max-width: 50% !important;
                        }

                        .col-12:nth-child(2) {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-end;
                            margin-top: 0 !important;
                        }
                    }
                }
            }
        }

        // Action Right Col
        .invoice-actions {
            display: none;
        }
    }
}
</style>
